<template>
  <div>
    <div v-if="IsDashboard" class="eh-dashboard-chart-title">
      <label>{{ $t("module.charts." + Chart.Name) }}</label>
    </div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="'Day'"
        :disableIntervalButtons="true"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <div
      :style="
        IsDashboard
          ? 'text-align: center; margin-bottom: -30px'
          : 'text-align: center; margin-top: 20px;margin-bottom:-80px'
      "
    >
      <b-icon :class="IsDashboard ? 'h3' : 'h1'" icon="arrow-down"></b-icon>
    </div>
    <ejs-accumulationchart
      :id="'pie-bmi-chart-component' + Id"
      v-if="seriesData"
      :legendSettings="legendSettings"
      enableSmartLables="true"
      :enableAnimation="enableAnimation"
      background="transparent"
      align="center"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '150px' : '110%'"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          :dataSource="seriesData"
          :emptyPointSettings="emptyPointSettings"
          :pointColorMapping="pointColorMapping"
          :startAngle="angle.startAngle"
          :endAngle="angle.endAngle"
          :explodeOffset="explodeOffset"
          :explodeIndex="explodeIndex"
          :radius="radius"
          xName="x"
          yName="y"
          :dataLabel="IsDashboard ? null : dataLabel"
          innerRadius="50%"
          explode="true"
        ></e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import {
  AccumulationChartPlugin,
  AccumulationTooltip,
  PieSeries,
  AccumulationDataLabel,
} from "@syncfusion/ej2-vue-charts";
import { ChartUiLogic } from "@/logic/ui/chart-ui";
import { ChartBackendLogic } from "../../../Logic/Backend/chart";
import IntervalManager from "../Components/IntervalManager.vue";

Vue.use(AccumulationChartPlugin);

export default {
  components: { IntervalManager },
  name: "ExerciseCalories",
  data() {
    return {
      currentDate: moment(),
      currentDateText: moment().format("LL"),
      interval: "Day",
      //Chart paraméterek
      emptyPointSettings: { mode: "Average" },
      max_bmi: 65,
      baseSeriesData: [18.5, 6.4, 4.9, 4.9, 30],
      BMIlevel: [
        {
          x: this.$t("module.charts.weightThin"),
          y: 18.5,
          range: "0-18.5",
          text: this.$t("module.charts.weightThin"),
          fill: "#2AB0F0",
        },
        {
          x: this.$t("module.charts.weightNormal"),
          y: 6.4,
          range: "0-18.5",
          text: this.$t("module.charts.weightNormal"),
          fill: "#92D04F",
        },
        {
          x: this.$t("module.charts.weightOverweight"),
          y: 4.9,
          range: "0-18.5",
          text: this.$t("module.charts.weightOverweight"),
          fill: "#FFFD02",
        },
        {
          x: this.$t("module.charts.weightFat"),
          y: 4.9,
          range: "0-18.5",
          text: this.$t("module.charts.weightFat"),
          fill: "#FCC003",
        },
        {
          x: this.$t("module.charts.weightVeryFat"),
          y: 30,
          range: "0-18.5",
          text: this.$t("module.charts.weightVeryFat"),
          fill: "#F90203",
        },
      ],
      seriesData: [],
      pointColorMapping: "fill",
      angle: {
        startAngle: 0,
        endAngle: 360,
      },
      dataLabel: {
        visible: true,
        position: "Inside",
        name: "text",
        font: {
          fontWeight: "600",
        },
        angle: 1,
        enableRotation: true,
      },
      enableSmartLabels: true,
      enableAnimation: false,
      legendSettings: {
        visible: false,
      },
      tooltip: { enable: true, format: "${point.x} : <b>${point.y}</b>" },
      radius: "70%",
      explodeOffset: "0%",
      explodeIndex: 0,
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    PatientId: Number,
    IsDashboard: Boolean,
    Id: [String, Number],
    Chart: Object,
  },
  provide: {
    accumulationchart: [PieSeries, AccumulationTooltip, AccumulationDataLabel],
  },
  methods: {
    async intervalChanged(currentDate, interval) {
      this.currentDate = currentDate;
      this.interval = interval;
      this.currentDateText = moment(this.currentDate).format("LL");
      await this.getChartData();
    },
    async getChartData() {
      // getting the data (content) of the chart
      if (this.PatientId) {
        const response = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
        if (!response.Code) {
          this.setChartData(response);
        } else {
          this.$bvToast.toast(response.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedData", {
              name: this.$t("module.charts." + this.Chart.Name),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      } else {
        const response = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
        if (!response.Code) {
          this.setChartData(response);
        } else {
          this.$bvToast.toast(response.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedData", {
              name: this.$t("module.charts." + this.Chart.Name),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    setChartData(chartAngleFromApi) {
      this.seriesData = [];
      this.angle = this.setChartAngel(chartAngleFromApi);
      this.seriesData = this.BMIlevel;
    },
    setChartAngel(data) {
      var norm = 360 / this.max_bmi;
      for (let i = 0; i < this.BMIlevel.length; i++) {
        this.BMIlevel[i].y = this.baseSeriesData[i] * norm;
      }
      this.BMIlevel.forEach((x) => {
        x.y = x.y * norm;
      });
      var bmiScore =
        data.length > 0 ? norm * data[0][this.Chart.DataProperty] : 0;
      return {
        startAngle: 0 - bmiScore,
        endAngle: 360 - bmiScore,
      };
    },
  },
  async mounted() {
    await this.getChartData();
  },
};
</script>

<style scoped>
@import '../style.css';
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
.dashboard-chart-title {
  text-align: center;
  color: var(--eh-secondary-5);
  cursor: pointer;
  margin-top: 10px;
}
</style>
