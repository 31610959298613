import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const CHART_API_PATH = '/chart'

export const ChartBackendLogic = {
    async getChart(diaryName, params) {
        let result = await ClientService.sendRequest(RequestMethod.GET, CHART_API_PATH + '/' + diaryName, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async getPatientChart(diaryName, patientId, params) {
        let result = await ClientService.sendRequest(RequestMethod.GET, CHART_API_PATH + '/' + diaryName + '/' + patientId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

}