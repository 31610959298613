<template>
  <div>
    <label class="eh-chart-title">{{ title }}</label>
    <div>{{ currentDateText }}</div>
    <b-row align-h="between" align-v="center">
      <div>
        <b-button @click="previousInterval">{{
          $t("base.basic.previous")
        }}</b-button>
      </div>
      <div>
        <b-button @click="today">{{ $t("base.basic.current") }}</b-button>
      </div>
      <div>
        <b-button @click="nextInterval">{{ $t("base.basic.next") }}</b-button>
      </div>
    </b-row>
    <b-row
      v-if="!disableIntervalButtons"
      align-h="center"
      style="margin-top: 10px"
    >
      <b-form-group>
        <b-form-radio-group
          @change="changeInterval"
          v-model="selectedInterval"
          id="btn-radios-2"
          :options="intervalOptions"
          buttons
          name="radio-btn-outline"
        >
        </b-form-radio-group>
      </b-form-group>
    </b-row>
  </div>
</template>
<script>
import moment from "moment";
import { ChartUiLogic } from "@/logic/ui/chart-ui";

export default {
  data() {
    return {
      /* intervalOptions: Object.values(this.$enums.ChartInterval).map(
        (interval) => {
          return { text: this.$t(interval.Text), value: interval.Value };
        }
      ), */
      selectedInterval: this.interval,
    };
  },
  props: {
    title: String,
    interval: String,
    currentDate: Object,
    currentDateText: String,
    disableIntervalButtons: Boolean,
  },
  computed: {
    intervalOptions() {
      return Object.values(this.$enums.ChartInterval).map((interval) => {
        return { text: this.$t(interval.Text), value: interval.Value };
      });
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    interval(input) {
      this.selectedInterval = input;
    },
    locale(input) {
      moment.locale(input);
      this.$emit(
        "intervalChanged",
        moment(this.currentDate),
        this.selectedInterval
      );
    },
  },
  methods: {
    nextInterval() {
      this.$emit(
        "intervalChanged",
        ChartUiLogic.IntervalManager.nextInterval(
          this.interval,
          this.currentDate
        ),
        this.interval
      );
    },
    previousInterval() {
      this.$emit(
        "intervalChanged",
        ChartUiLogic.IntervalManager.previousInterval(
          this.interval,
          this.currentDate
        ),
        this.interval
      );
    },
    today() {
      this.$emit(
        "intervalChanged",
        ChartUiLogic.IntervalManager.today(),
        this.interval
      );
    },
    changeInterval(event) {
      this.$emit("intervalChanged", moment(), event);
    },
  },
  moment() {
    console.log(this.locale)
    moment.locale(this.locale);
  },
};
</script>
<style scoped>
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
</style>
